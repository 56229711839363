@media only screen and (max-width: 680px) {
    .how__step {
        flex-wrap: wrap-reverse !important;
        justify-content: center;
        text-align: center;
    }
    
    .benefits {
        flex-wrap: wrap;
    }

    .hero__left {
        width: 100% !important;
    }
    .hero-img {
        left: unset !important;
        width: 100%;
        bottom: 0;
        height: unset !important;
        opacity: 20%;
    }    
  }

  @media only screen and (min-width: 681px) and (max-width: 930px) {
    .hero__left {
        width: 300px !important;
    }
    .hero-img {
        left: unset !important;
        height: 80% !important;
        bottom: 0;
        right: 0;
    }
  }

.shiny-bg {
    background-color: #fcf9ee;
}

.hero {
    padding: 0 20px;
    text-align: center;
    margin: -8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.hero h1 {
    color: #333;
}

.hero p {
    font-size: 18px;
}

.hero button {
    padding: 10px 20px;
    background-color: #f4dc8f;    
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 6px;
}

.hero__left {
    width: 500px;
    margin: 50px;
    z-index: 1;
}

.hero__right {
    display: flex;
    position: relative;
    flex-grow: 1;
    max-width: 300px;
}

.hero-img {
    position: absolute;
    height: 100%;
    object-fit: contain;
    left: 0;
}

.hero__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.highlight {
    color: #9e6202;
}

.benefits {
    display: flex;
    flex-direction: row;
}

.benefits__item {
    display: flex;
    flex-direction: column;
    margin: 0 8px;
    align-items: center;
    text-align: center;
}

.benefits__icon {
    font-size: 4em;
}

.how {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.how__step {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 800px;
}

.how__text {
    display: flex;
    flex-direction: column;
    margin: 20px;
    justify-content: center;
    max-width: 300px;
}

.how__step__img{
    height: 400px;
    margin: 20px;
    flex-shrink: 0;    
}

.how__step.left {
    flex-direction: row;
}

.how__step.right {
    flex-direction: row-reverse;
}

.how, .step, .benefits, .testimonials {
    padding: 50px 20px;
}

.step h3, .benefits ul li, .testimonials blockquote {
    margin-top: 0;
}

.img-placeholder {
    width: 150px;
    height: 300px;
    background-color: grey;
}

.testimonial {
    font-style: italic;
    width: 400px;
    text-align: center;
}

.testimonial .author {
    font-style: normal;
    font-weight: 600;
}

