@font-face {
  font-family: 'Comfortaa';
  src: url('./fonts/Comfortaa-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* unvisited link */
a:link {
  color: #333;
}

/* visited link */
a:visited {
  color: #888;
}

/* mouse over link */
a:hover {
  color: rgb(255, 195, 105);
}

/* selected link */
a:active {
  color: #888;;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Comfortaa';
  line-height: 150%;
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  position: relative;
  background-color: #fff;
  padding: 10px 20px;
  text-align: right;
}

.logo{
  font-size: 24px;
  color: #333;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}

.logo a {
  color: #333;
  text-decoration: none;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-left: 20px;
}

.page {
  padding: 8px;
  /* line-height: 1.6; */
  margin: 0;
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.content p {
  margin-right: auto;
}

.about-image {
  margin: 20px 0;
  width: 100%;
  max-width: 600px;
}

.img-small {
  max-height: 250px;
  max-width: 250px;
}

.img-medium {
  max-height: 450px;
  max-width: 450px;
}

footer {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

footer a:link {
  color: white;
}

footer ul {
  margin: auto;
}